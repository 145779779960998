<template>
  <div>
    <Message v-if="message" :isResend="isForgot" :type="message.type" :message="message.message" :content="message.content" />
    <div v-if="!message" class="modal-mask">
      <div class="modal-wrapper">
        <div :class="{'isForgot': isForgot, 'isReset': isReset}" class="modal-container">
          <div class="modal-header">
            <div class="modal-heading">
              <p v-if="isForgot">FORGOT YOUR PASSWORD?</p>
              <span class="text" v-if="isForgot">No worries. Enter your email address and we'll help you reset it. </span>
              <p v-if="isReset">RESET YOUR PASSWORD?</p>
            </div>
            <span @click="close" class="pointer close">
              <img src="@/assets/icons/close_icon.png" />
            </span>
          </div>
          <div class="modal-body">
            <Loader class="loader" v-if="isLoader"/>
            <div class="center-wrapper">
            <!-- For Forgot password -->
            <form @submit="submit" v-if="isForgot" class="input-wrapper">
              <div class="input-container">
                <span><img src="@/assets/icons/user_icon.png" /></span>
                <input class="input-field" type="text" placeholder="Your email address" v-model="forgot.identifier">
                <p v-if="errors.hasOwnProperty('FGIdentifier')" class="error">Field required</p>
                <p v-if="errors.hasOwnProperty('FGIdentifierRegex')" class="error">Email not found!</p>
              </div>
              <div class="input-wrapper-button mx-1">
                <button class="black pointer" @click="onForgotPswd">FORGOT PASSWORD</button>
              </div>
            </form>
            <!-- For Change Password -->
            <form @submit="submit" v-else-if="isReset" class="input-wrapper">
              <div class="input-container">
                <span><img src="@/assets/icons/password.png" /></span>
                <input class="input-field" type="password" placeholder="Enter your new password" v-model="reset.password">
                <p v-if="errors.hasOwnProperty('password')" class="error">Field required</p>
              </div>
              <div class="input-container">
                <span><img src="@/assets/icons/password.png" /></span>
                <input class="input-field" type="password" placeholder="Confirm password" v-model="reset.cpassword">
                <p v-if="errors.hasOwnProperty('cpassword')" class="error">Field required</p>
                <p v-else-if="errors.hasOwnProperty('cpassword_different')" class="error">Password and confirm password not same</p>
              </div>
              <div class="input-wrapper-button mx-1">
                <button class="black pointer" @click="onResetPswd">RESET PASSWORD</button>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Message from '@/components/molecules/Message'
import { mapState } from 'vuex'
import Loader from '@/components/molecules/Loader'
export default {
  data () {
    return {
      isForgot: false,
      isReset: false,
      errors: {},
      forgot: {
        identifier: ''
      },
      reset: {
        password: '',
        cpassword: ''
      },
      emailRegex: /\S+@\S+\.\S+/
    }
  },
  components: {
    Loader,
    Message
  },
  computed: {
    ...mapState(['isLoader', 'message'])
  },
  mounted() {
    if (this.$route.query && this.$route.query['code']) {
      this.isReset = true
    } else {
      this.isForgot = true
    }
  },
  methods: {
    submit (e) {
      e.preventDefault()
    },
    validateEmail () {
      this.errors = {}
      if (!this.forgot['identifier']) {
        this.$set(this.errors, 'FGIdentifier', 'required')
      } else if (!this.emailRegex.test(this.forgot['identifier'])) {
        this.$set(this.errors, 'FGIdentifierRegex', 'required')
      }
      if (Object.getOwnPropertyNames(this.errors).length === 1) {
        return true
      }
    },
    onForgotPswd () {
      if (this.validateEmail()) {
        this.$store.dispatch('auth/forgotPassword', {email: this.forgot['identifier']})
      }
    },
    validateResetPswd () {
      this.errors = {}
      if (!this.reset['password']) {
        this.$set(this.errors, 'password', 'required')
      }
      if (!this.reset['cpassword']) {
        this.$set(this.errors, 'cpassword', 'required')
      } else if (this.reset['cpassword'] !== this.reset['password']) {
        this.$set(this.errors, 'cpassword_different', 'required')
      }
      if (Object.getOwnPropertyNames(this.errors).length === 1) {
        return true
      }
    },
    onResetPswd () {
      if (this.validateResetPswd()) {
        const data = {
          code: this.$route.query['code'],
          password: this.reset['password'],
          passwordConfirmation: this.reset['cpassword']
        }
        this.$store.dispatch('auth/resetPassword', data)
      }
    },
    close () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../sass/login.sass'
@import '../../sass/modal.sass'
</style>
